<template>
  <el-drawer
    title='操作日志'
    :visible.sync='drawer'
    :direction='direction'
    size='60%'
  >
    <div style='padding: 1px 20px'>

      <el-link icon='el-icon-refresh' @click='handlerQuery'>刷新日志({{ searchCondition.nickname || '' }})</el-link>
      <div class='default-table'>
        <el-table :data='logList' :loading='loadingStaus'>
          <el-table-column
            width='80'
            align='center'
            label='序号'>
            <template slot-scope='scope'>
              <span>{{ offset + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width='100'
            align='center'
            label='日志ID'
            prop='id'
            >
          </el-table-column>
          <el-table-column align='left' label='操作内容' prop='message'>

          </el-table-column>
        </el-table>
        <m-pagination :limit.sync='pageData.page_size' :page.sync='pageData.current_page' :total.sync='total'
                      @pagination='getList'></m-pagination>
      </div>
    </div>
  </el-drawer>


</template>
<script>

export default {
  name: 'RevenueDataLog',
  data() {
    return {
      drawer: false,
      direction: 'rtl',
      searchCondition: {},
      logList: [],
      loadingStaus: false,
      // 分页的offset,序号列使用
      offset: 0,
      total: 0,
      pageData: {
        page_size: 20,
        current_page: 1
      }
    }
  },
  methods: {
    show(searchCondition) {
      this.drawer = true
      this.searchCondition = { ...searchCondition }
      this.handlerQuery()
    },
    handlerQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.logList = []
      if (this.searchCondition.year && this.searchCondition.artist_id) {
        let searchCondition = this.searchCondition
        // 合并
        Object.assign(searchCondition, this.pageData)
        let { list, pages } = await this.$api.getRevenueDataLog(searchCondition)
        this.$nextTick(() => {
          this.logList = list || []
        })
        this.pageData.page_size = pages.page_size || 10
        this.pageData.current_page = pages.current_page || 1
        this.total = pages.total || 0
        this.offset = pages.offset
        this.loadingStaus = false
      } else {
        this.loadingStaus = false
      }
    }
  }
}
</script>

<style scoped>
.box-card {
  margin-top: 10px;
}
</style>
