<template>
  <div style='display: inline-block'>
    <div v-for='(platform,key ) in platforms' :key='key' style='display: inline'>
      <el-image
        style='width: 20px; height: 20px; padding: 2px;'
        :class="`${showPlatform(platform.code)?'platform-yes':'platform-no'}`"
        :src="`${platform.logo_url?platform.logo_url:'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'}`"
        fit='contain'>
      </el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlatformLogos',
  props: {
    platforms: {
      type: Array,
      default() {
        return []
      }
    },
    kolPlatforms: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    showPlatform(code) {
      let isShow = false
      let myPlatforms = this.kolPlatforms
      if (myPlatforms && code) {
        isShow = myPlatforms[code] === 'Y'
      }

      return isShow
    }
  }
}
</script>

<style scoped>
/**
opacity 在IE8及以下是不兼容的；所以要设置filter属性
支持firefox : -moz-opacity:0.3;
 */
.platform-no {
  opacity: 0.2;
  -moz-opacity: 0.2;
  filter: alpha(opacity=20);
}
</style>
